@import "./../assets/scss/SharedColors";
@import "./../assets/scss/SharedDimentions";

// Version mobile
// @media screen and (max-width: 1023px) {
//   .navbar {
//     font-weight: 500 !important;

//     position: fixed;
//     width: 100vw !important;
//     top: 0 !important;
//     z-index: 99 !important;

//     & + div {
//       margin-top: $navbar-height !important;

//       &.container {
//         margin-top: calc(#{$navbar-height} + 50px) !important;
//       }
//     }
//   }
// }

@media screen and (min-width: 1024px) and (max-width: 1087px) {
  .navbar-menu {
    box-shadow: none !important;
    padding: 0 !important;
  }

  .navbar-burger {
    display: none !important;
  }

  .navbar-start {
    flex-grow: 1 !important;
  }

  .navbar,
  .navbar-menu {
    width: 100% !important;
  }

  .navbar,
  .navbar-end,
  .navbar-menu,
  .navbar-start {
    align-items: stretch !important;
    display: flex !important;
  }
}

.nav-c-and-i {
  background-color: #c0b38a !important;
  border-bottom: 1px solid #c0b38a !important; //MAJ EVENT
  border-left: 5px solid #c0b38a !important; //MAJ EVENT
  .navbar-menu {
    background-color: #c0b38a !important; //MAJ EVENT
    a {
      color: white;
      height: 100%;
    }
  }
  .navbar-brand {
    background-color: #c0b38a !important; //MAJ EVENT
  }
  .pop-up.active {
    background-color: #c0b38a !important;
    border: solid 1px #c0b38a !important;
  }
}

.navbar {
  i {
    display: inline !important;
  }
  font-size: 1rem;
  border-bottom: 1px solid $green; //MAJ EVENT
  // border-left: 5px solid $green; //MAJ EVENT
  height: 70px !important;
  min-height: 70px !important;

  .navbar-menu {
    background-color: $green; //MAJ EVENT
    
    a {
      color: #006276;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .navbar-item {
    height: $navbar-height;
    line-height: $navbar-height;
    padding: 0 1vw;
    color: #006276;
    &.account {
      height: 100%;

      .toggler {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .pop-up {
        display: none;
      }

      .pop-up.active {
        display: block;
        position: absolute;
        top: 100%;
        right: 0;
        background-color: $green; //MAJ EVENT
        border: solid 1px $green;
        width: 100%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        li {
          display: block;
          cursor: pointer;

          a {
            padding: 10px;
            height: fit-content;
            line-height: normal;
          }
          &:hover {
            background-color: $light;
            color: #006276;
            a {
              color: #006276;
            }
          }
        }
      }
    }

    &:hover {
      background-color: $light;
      color: #006276; //MAJ EVENT
      height: 100%;
    }
  }
  .navbar-end,
  .navbar-start {
    align-items: center;
  }

  .navbar-brand {
    background-color: $green; //MAJ EVENT
    position: relative;
    align-items: center;
    height: 100%;

    .navbar-item {
      display: block;
      padding: 0 1em;
      .logo-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }
      &:hover {
        background-color: white;
        height: 100%;
      }
    }
  }
}
