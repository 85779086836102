@import "./../../assets/scss/SharedColors";
@import "./../../assets/scss/SharedDimentions";

$side_navbar_trigger_size: 25px;
.carousel {
  .slider-wrapper {
    height: 100%;
    .slider {
      height: 100% !important;
      .slide {
        overflow-x: hidden;
        overflow-y: hidden;
      }
    }
  }
}

.arrow {
  &:hover {
    background-color: $optiwizeBlue !important;
  }
}

.optim-cta {
  position: fixed;
  top: calc(58% - 15px);
  right: 40px;
  width: 10em;
  text-align: center;
  color: $blue;
  font-weight: bold;
  padding: 10px;
  padding-right: 0;
}
@media screen and (min-width: 800px) {
  #side_navbar {
    a {
      font-size: 15px;
    }

    .trigger {
      display: none;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 800px) {
  .download-consoData {
    font-size: 0.6em !important;
  }
}

@media screen and (max-width: 768px) {
  .download-consoData {
    font-size: 1em !important;
  }
}

@media screen and (max-width: 800px) {
  #side_navbar {
    border-right: 1px solid #b5b5b5;
  }

  .sites-wrapper,
  input {
    font-size: 15px !important;
  }
}

@media screen and (min-width: 800px) and (max-width: 1024px) {
  .sites-wrapper,
  input {
    font-size: 13px !important;

    button {
      font-size: 13px !important;
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1087px) {
}

#side_navbar.isMobile {
  background-color: white;
  box-sizing: border-box;
  top: $navbar-height;
  position: fixed;
  border-top: $green 5px solid;
  z-index: 98;

  width: $side_navbar-closed-width;
  padding: 0;
  height: 100%;
  overflow: hidden;

  .trigger {
    display: block;
    background: $green;
    font-size: 22px;
    text-align: center;
    position: fixed;
    z-index: 1;
    top: calc(100vh / 2 - #{$side_navbar_trigger_size} / 2);
    left: #{$side_navbar-closed-width - $side_navbar_trigger_size / 2};
    width: $side_navbar_trigger_size;
    height: 40px;
    line-height: 40px;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    border: 0.1px solid #b5b5b5;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

    &:hover {
      transform: scale(1.1);
    }
  }

  li {
    white-space: nowrap;
    overflow: hidden;
  }

  .aside-menu-wrapper {
    padding: 0;
    width: $side_navbar-closed-width !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    a i {
      margin-right: 25px;
    }
  }

  & + div {
    padding-left: calc(#{$side_navbar-closed-width} + 0.75rem);
    width: 100% !important;
  }

  &.active {
    .aside-menu-wrapper {
      width: $side_navbar-open-width !important;
    }

    .trigger {
      left: #{$side_navbar-open-width - $side_navbar_trigger_size / 2};

      &:hover {
        transform: scale(1.1);
      }

      i {
        transform: rotate(180deg);
      }
    }
  }
}

.dashboard-wrapper {
  padding-top: 25px;

  .button-create-card {
    background-color: $green;
    color: white;
    padding: 0 50px;
    transition: all 0.2s ease;

    &:hover {
      background-color: white;
      color: $green;
      border: 1px solid $green;
    }
  }

  .list-cards__Empty {
    text-align: center;

    .button-create-card {
      margin-top: 20px;
    }

    i {
      font-size: 3rem;
      margin-bottom: 20px;
    }
  }

  .home-card {
    .home-card-dropdowns {
      padding: 20px;

      .dropdown {
        margin: 10px 0 0 0;

        button {
          font-size: 0.8rem;
        }
      }
    }

    .home-card-result {
      background-color: #e3f2fd;
      text-align: center;
      padding: 20px 0;
      font-size: 2rem;
      font-weight: 700;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      &:hover {
        cursor: pointer;
        box-shadow: 0 0 8px 0px #b5acac;
      }
    }

    .home-card-result-not-clickable {
      background-color: #e3f2fd;
      text-align: center;
      padding: 20px 0;
      font-size: 2rem;
      font-weight: 700;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .home-card-actions {
      text-align: center;
      font-size: 1.5rem;

      i {
        opacity: 0.6;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .home-card__New {
    cursor: pointer;
    background-color: white;
    color: $green;
    transition: all 0.2s ease;

    &:hover {
      color: white;
      background-color: $green;
    }
  }
}

.search-container input {
  font-size: 15px;
}

.sites-wrapper {
  font-size: 1rem;
  padding-top: 25px;

  .sort-container {
    display: flex;
    width: 30%;
    justify-content: space-evenly;
    margin: auto;

    .img-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      i {
        font-size: 20px;
        width: $icon_size;
        height: $icon_size;
        line-height: $icon_size;
        color: $green;
        text-align: center;
        border-radius: 5px;

        &:hover {
          background: $lightGray;
          color: white;
        }

        &.active {
          background: $green;
          color: white;
        }
      }

      &:hover {
        cursor: pointer;
        transform: scale(1.1);
        transition: transform 0.2s ease-in-out;
      }

      .sort-scenario {
        padding: 10px;
        border-radius: 5px;
        width: 43%;
        margin: auto;

        &.active {
          background-color: $green;
        }
      }
    }
  }

  .button-ask-demo {
    color: white;
    margin-top: 2em;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }

  .list-sites__Empty {
    text-align: center;

    .button-create-site {
      background-color: $green;
      margin: auto;
      margin-top: 20px;
      border: 1px solid $green;
    }

    i {
      font-size: 3rem;
      margin-bottom: 20px;
    }
  }

  a {
    width: fit-content;
  }

  .list-sites {
    .search-container {
      width: 90%;
      margin: 10px auto;

      input {
        box-shadow: 0 1px 6px 1px hsla(0, 0%, 4%, 0.1);
      }
    }

    .list {
      & > div:first-child {
        margin-top: 25px;
        border-top: 1px solid rgba(128, 128, 128, 0.4);
      }

      .download-consoData {
        font-size: 0.85rem;
        display: block;
        margin-top: 15px;

        i {
          font-size: 1rem;
        }
      }

      .site-metadata {
        .site-metadata-name {
          color: $green;
          font-size: 1.25rem;
          font-weight: 500;
        }

        .site-metadata-address {
          color: rgba(0, 0, 0, 0.5);
        }
      }

      .site-description {
        text-align: center;
      }

      .site-actions {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;

        .button {
          width: 100%;
        }

        .analyze-button {
          width: 100%;
          margin-bottom: 3px;
          color: white;

          &:hover {
            color: white;
          }
        }

        .fetching {
          width: 20px;
          height: 20px;
          margin: auto;
          border: 1.5px solid $green;

          border-right-color: transparent;
          border-top-color: transparent;
        }
      }
    }

    .site-cta {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      align-items: center;

      a {
        display: block;
        margin: 0;
      }

      button {
        &:disabled {
          background-color: $green !important;
          color: white;

          &:hover {
            background-color: white;
          }
        }
      }
    }
  }
}

.create-site-wrapper {
  padding: 25px 0;

  .subtitle {
    margin-bottom: 10px;
    color: grey;
    font-size: 0.85rem;
    font-weight: 300;
  }

  .rsm-svg {
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.397);
  }

  .next-button {
    padding-left: 25px;
    padding-right: 25px;
    color: white;
    padding: 0 50px;
    transition: all 0.2s ease;
    margin-bottom: 30px;
  }

  table {
    margin-top: 10px;

    tbody {
      & > tr:last-child {
        td {
          border-bottom-width: 0 !important;
        }

        padding: 0;
        border-top-width: 0;
        background-color: white !important;

        button {
          padding: 5px 10px;
          font-size: 0.75rem;
          height: auto;
        }
      }

      td {
        text-align: center;
      }
    }

    td {
      border-bottom-width: 1px !important;
    }

    thead {
      th {
        text-align: center;
        font-weight: 400;
        font-size: 0.75rem;
        text-transform: uppercase;
        border-bottom-width: 1px !important;
        background-color: #f1f1f1;
      }
    }
  }

  .subscribed-powers-table {
    tbody {
      font-size: 1em;

      td {
        padding: 0.5rem;
        vertical-align: middle;
      }

      .input {
        font-size: 1em !important;
      }
    }
  }

  .subscription-rates-table {
    tbody {
      font-size: 0.5rem;

      td {
        padding: 0.5rem;
        vertical-align: middle;
      }

      .input {
        font-size: 1em !important;
      }
    }
  }

  .has-margin-vertical {
    margin-top: 40px;
    margin-bottom: 10px;

    p {
      font-size: 1.25rem;
      margin-bottom: 1em;
    }

    .text-input {
      width: 50%;
      margin: auto;
      text-align: center;
      font-size: 1.2rem;
    }

    .consent {
      display: flex;
      gap: 5px;
      align-items: center;

      p {
        font-size: 1em;
        flex-grow: 1;
      }

      .agreement-checkbox {
        min-width: 20px;
        width: 20px;
        margin-right: 1em;
      }
    }
  }

  .submit-new-site {
    padding: 0 60px;
  }
}

.modal-delete-site {
  .delete {
    display: none;
  }
}

.CGU-to-home {
  border-radius: 4px;
  display: block;
  width: 100%;
  text-align: center;
  margin: 50px 0;
  padding: 16px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.analyze-collapse {
  width: 90%;
  margin: auto;
  padding-bottom: 1em;

  .analyze-collapse-header {
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 5px 25px;
    margin-bottom: 20px;
    cursor: pointer;
    color: $green;

    i {
      margin-left: 15px;
    }
  }
}

.terms-container {
  height: 100%;
  margin-bottom: 50px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  padding: 20px;
  margin: auto;
  width: 100%;

  h1 {
    font-size: 2rem;
    margin: 30px 0;
  }

  h2 {
    font-size: 1.5rem;
    margin: 0 0 20px 0;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
