@import "./assets/scss/SharedColors";
@import "./assets/scss/SharedDimentions";
@import "./assets/scss/Typography";

.gm-style-cc {
  visibility: hidden !important;
}

#event-logo {
  position: absolute;
  top: 10%;
  left: 50%;
  right: 50%;
  height: auto;
  width: 5%;
  bottom: 0;
}

.new-logo {
  margin: auto;
  max-width: 50%;
  max-height: 25rem;
  margin-top: 2%;
  display: flow-root;
}

.gm-style-moc {
  visibility: hidden !important;
}

input {
  min-height: 36px;
}

.label {
  font-size: 1rem !important;
}

html {
  overflow-y: auto !important;
  position: fixed;
}

html,
body {
  background: white;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "Roboto" !important;

  font-size: 1rem !important;
  .container__Columns {
    margin: 0 0.75rem;
  }

  /* Style of Aside menu */
  .aside-menu-wrapper {
    max-height: calc(100vh - #{$navbar-height});
    padding-top: 7%;
    border-left: 5px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;

    ul {
      li {
        a {
          transition: all 0.1s ease;
          font-size: 1rem;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;

          &.not-clickable {
            cursor: default;

            &:hover {
              color: white !important;
            }
          }

          &.is-active {
            background-color: $lightGray;
          }

          &:hover {
            background-color: $lightGray;
            color: black;
          }

          i {
            margin-right: 15px;
            font-size: 1rem;
            width: 20px;
          }
        }

        ul {
          li {
            a {
              span {
                color: rgb(2, 2, 2);
                font-size: 0.75rem;
              }
            }

            &:hover {
              background: rgba(214, 214, 214, 0.776);

              a {
                color: white !important;

                i {
                  font-size: inherit !important;
                }

                span {
                  color: white;
                }
              }
            }
          }
        }
      }

      > .not-clickable {
        &:hover {
          color: white !important;
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    // -webkit-box-shadow: inset 0 0 6px rgba(158, 190, 90, 1);
    background: #ccc;
  }
}

// create same red for all buttons

.is-danger {
  background-color: red !important;
}

.my-progress {
  position: relative;
  height: 8px;
  display: block;
  width: 100%;
  background-color: #e8e8e8;
  border-radius: 2px;
  margin: 0.5rem 0 1rem 0;
  overflow: hidden;

  .indeterminate {
    background-color: #94bf48;

    &:before {
      content: "";
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      -webkit-animation: indeterminate 2.1s
        cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
      animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
        infinite;
    }

    &:after {
      content: "";
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      -webkit-animation: indeterminate-short 2.1s
        cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
        infinite;
      -webkit-animation-delay: 1.15s;
      animation-delay: 1.15s;
    }
  }
}

/* A is-fullheight class for column not included in Bulma */
.columns {
  &.is-fullheight {
    // min-height: calc(100vh - (#{$navbar-height} - 0.75rem));
    max-height: calc(100vh - (#{$navbar-height} - 0.75rem));
    // height: calc(100vh - (#{$navbar-height} - 0.75rem));
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    // margin-top: 1.15rem;
  }
}

.infoBar-container {
  width: 100%;
  border-left: 5px solid #9ebe5a;
  height: 100%;

  .infoBar-content {
    position: relative;
    z-index: 10;
    width: 95%;
    margin: auto;
    padding: 15px 0;
    border-radius: 5px;
    color: white;

    .notification {
      display: flex;
      justify-content: space-between;

      background-color: #259116c7;
    }
  }
}

.container-cookie-consent {
  align-items: baseline;
  background: rgb(158, 190, 90);
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
  font-size: 1rem;
  bottom: 0px;

  .content-cookie-consent {
    flex: 1 0 300px;
    margin: 15px 15px 15px 10%;
  }

  .btn-cookie-consent {
    background: rgb(35, 209, 96);
    border: 0px;
    border-radius: 5px;
    box-shadow: none;
    color: white;
    cursor: pointer;
    flex: 0 0 auto;
    padding: 5px 10px;
    margin: 15px;
    font-size: 1rem;
  }
}

.introjs-tooltipbuttons {
  a {
    background-color: $green;
    color: white;
    transition: transform 0.2s ease-in-out;
    font-weight: 100;
    text-shadow: none;

    &:focus {
      background-color: $green;
      color: white;
      box-shadow: 0 0 0 0.2rem $green;
      border-color: white;
    }

    &:hover {
      background-color: $green;
      color: white;
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}

.new-logo {
  margin: auto;
  max-width: 50%;
  max-height: 25rem;
  margin-top: 2%;
  display: flow-root;
}

@media (max-width: 485px) {
  .container-cookie-consent {
    div:nth-of-type(2n) {
      width: 100%;
      text-align: right;
    }
  }
}

/* Some helpers not incldued in Bulma */

.has-margin-left__Small {
  margin-left: 0.25rem !important;
}

.has-margin-right__Small {
  margin-right: 0.25rem !important;
}

.has-no-padding-horizontal {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.has-no-padding-vertical {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/* Fullwidth Bulma Dropdown & custom colored */
.dropdown.is-fullwidth {
  display: flex;
}

.dropdown.is-fullwidth * {
  width: 100%;
}

.dropdown .dropdown-item {
  &.is-active {
    background-color: #94bf48;
  }
}

// .modal-container{
//   z-index: 999999 !important;
// }
/* No decoration on text when button is-text */
.button.is-text {
  text-decoration: none !important;
}

.navbar-link.is-active,
a.navbar-item.is-active {
  color: #003b58;
  background-color: white !important;
}

/* Fix aligning of the text which is by default set to text-align: left (why ?)*/
.field .control {
  text-align: inherit;
}

/* Large modal (for Tech config) */
@media (min-width: 769px) {
  .modal-card.modal-large {
    width: 740px;
  }
}

/* Large modal (for Tech config) */
@media (min-width: 1100px) {
  .modal-card.modal-large {
    width: 900px;
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

.body-container {
  //   overflow-x: scroll;
  margin-top: 1%;

  &.column {
    padding: 0;
  }
}

.event-popup {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.466);
  .close-button {
    position: absolute;
    top: 0;
    right: 0;
  }
  .content {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.update-app-button-container {
  width: 100%;
  //   position: absolute;
  display: flex;
  justify-content: center;
  padding: 1em 0;
  button {
    &:hover {
      cursor: pointer;
      background-color: white !important;
      color: $green !important;
      border: 1px solid $green !important;
    }
  }
}

.custom-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker__time-container {
  z-index: 9999 !important;
  position: absolute;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
