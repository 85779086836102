.shortcut-page {
  margin: auto;
  .shortcut-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: auto;
    margin-top: 15%;
    justify-content: center;
    text-align: center;
    a {
      width: 20%;
      margin: auto;
    }
    p {
      font-size: 3em;
    }
    #icon {
      margin: auto;
      width: 20%;
    }
    section {
      p {
        font-size: 2em;
      }
      i {
        margin-right: 10px;
        font-size: 1em;
      }
    }
  }
}
