.maplabel {
  background-color: rgba(0, 0, 0, 0.438);
  border: solid 2px transparent;
  border-radius: 5px;
  padding: 0.3rem !important;
  backdrop-filter: blur(2px);
}
.marker {
  z-index: 4574;
}

.custom-control {
  margin: 5px;
  z-index: 10;
  position: absolute;
  top: 0px;
}

.custom-control-button {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.custom-control-button-center,
.custom-control-button,
.custom-control-button-right {
  background: none padding-box rgb(255, 255, 255);
  border: 0px;
  margin: 0px;
  padding: 4px;
  text-transform: none;
  appearance: none;
  cursor: pointer;
  color: rgb(86, 86, 86);
  font-family: Roboto, Arial, sans-serif;
  font-size: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  &.active {
    background-color: #bbbbbb;
  }
  &:hover {
    background-color: #ececec;
  }
}


.custom-control-button-right {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
