$green: #9dcf56; // green de Likewatt
$greenOpac: rgba(158, 190, 90, 0.5);
$lightGray: rgba(201, 201, 201, 0.5);
$blue: #006276;
$warning: #37b06e;
$dark: rgb(36, 36, 36);
$light: #fafafa;
$optim: #008a7d;
$danger: #b93217;
$optiwizeBlue: #003b58;
.tabs {
  ul {
    li {
      &.is-active {
        a {
          background-color: $lightGray !important;
          color: "white";
        }
      }
    }
  }
}
